// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experienceqs-js": () => import("./../../../src/pages/experienceqs.js" /* webpackChunkName: "component---src-pages-experienceqs-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learnqs-js": () => import("./../../../src/pages/learnqs.js" /* webpackChunkName: "component---src-pages-learnqs-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-qmanagement-js": () => import("./../../../src/pages/qmanagement.js" /* webpackChunkName: "component---src-pages-qmanagement-js" */),
  "component---src-pages-scapital-js": () => import("./../../../src/pages/scapital.js" /* webpackChunkName: "component---src-pages-scapital-js" */),
  "component---src-pages-speaking-js": () => import("./../../../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-twelveprinciples-js": () => import("./../../../src/pages/twelveprinciples.js" /* webpackChunkName: "component---src-pages-twelveprinciples-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-whatissq-js": () => import("./../../../src/pages/whatissq.js" /* webpackChunkName: "component---src-pages-whatissq-js" */),
  "component---src-templates-book-template-js": () => import("./../../../src/templates/bookTemplate.js" /* webpackChunkName: "component---src-templates-book-template-js" */)
}

